export default [
	{
		name: ".32 Revolver",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 0,
		spread: 2,
		bullets: 6,
		canshoot: 1,
		hud_str: "sRevolverHUD",
		pickup_str: "sRevolverPickup",
		id: "Revolver",
		is2hand: 0,
		recoil: 2,
		description: "Accurate. Holds 6 bullets.",
		dmg: 50,
		hs_multi: 3,
		pierce: 0,
		frames: 7,
		hs_dmg: 150,
		type: "revolver",
		group: "Revolver",
		firemode: 3,
		trunk_group: 1,
		cost: 0,
		can_buy: 1,
		auto_unlock: 1,
		m_hit_max: -1,
		alert_range: 180,
		imp_amount: 0.8,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "9mm Pistol",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 1,
		spread: 2,
		bullets: 10,
		canshoot: 1,
		hud_str: "sPistolHUD",
		pickup_str: "sPistolPickup",
		id: "Pistol",
		is2hand: 0,
		recoil: 6,
		description: "High capacity pistol. Holds 10 bullets.",
		dmg: 50,
		hs_multi: 2,
		pierce: 0,
		frames: 4,
		hs_dmg: 100,
		type: "pistol",
		group: "Pistol",
		firemode: 3,
		trunk_group: 1,
		cost: 30,
		can_buy: 1,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 180,
		imp_amount: 0.8,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Sawn-Off Shotgun",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 2,
		spread: 20,
		bullets: 2,
		canshoot: 1,
		hud_str: "sSawedHUD",
		pickup_str: "sSawedPickup",
		id: "Sawed",
		is2hand: 0,
		recoil: 35,
		description: "High damage, high recoil. Holds 2 slugs.",
		dmg: 400,
		hs_multi: 1,
		pierce: 0,
		frames: 6,
		hs_dmg: 400,
		type: "shotgun",
		group: "Shotgun",
		firemode: 3,
		trunk_group: 1,
		cost: 0,
		can_buy: 0,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 230,
		imp_amount: 0.75,
		sg_bullet_count: 6,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Hammer",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 3,
		spread: 0,
		bullets: 1,
		canshoot: 0,
		hud_str: "sHammerHUD",
		pickup_str: "sHammerPickup",
		id: "Hammer",
		is2hand: 0,
		recoil: 0,
		description: "Medium speed. Can be thrown.",
		dmg: 80,
		hs_multi: 1,
		pierce: 0,
		frames: 0,
		hs_dmg: 80,
		type: "melee",
		group: "Melee",
		firemode: 1,
		trunk_group: 2,
		cost: 20,
		can_buy: 1,
		auto_unlock: 0,
		m_hit_max: 1,
		alert_range: 0,
		imp_amount: -1,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: 160,
		t_damage_gib: 50,
		t_force: 1,
		t_can_lodge_body: 0,
		t_can_lodge_wall: 0,
		t_impact_sound: 60,
	},
	{
		name: "Tommy Gun",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 5,
		spread: 3,
		bullets: 40,
		canshoot: 1,
		hud_str: "sTommyHUD",
		pickup_str: "sTommyPickup",
		id: "Tommy",
		is2hand: 1,
		recoil: 3,
		description: "High damage. Holds 40 rounds.",
		dmg: 25,
		hs_multi: 2,
		pierce: 0.3,
		frames: 5,
		hs_dmg: 50,
		type: "rifle",
		group: "Rifle",
		firemode: 4,
		trunk_group: 1,
		cost: 0,
		can_buy: 0,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 300,
		imp_amount: 0.8,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Suppressed 9mm Pistol",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 6,
		spread: 2,
		bullets: 10,
		canshoot: 1,
		hud_str: "sSPistolHUD",
		pickup_str: "sSPistolPickup",
		id: "SPistol",
		is2hand: 0,
		recoil: 5,
		description: "Silent weapon, but lower damage. Holds 10 bullets.",
		dmg: 35,
		hs_multi: 3,
		pierce: 0,
		frames: 4,
		hs_dmg: 105,
		type: "pistol",
		group: "Pistol",
		firemode: 3,
		trunk_group: 1,
		cost: 80,
		can_buy: 1,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 0,
		imp_amount: 0.6,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Knife",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 7,
		spread: 0,
		bullets: 1,
		canshoot: 0,
		hud_str: "sKnifeHUD",
		pickup_str: "sKnifePickup",
		id: "Knife",
		is2hand: 0,
		recoil: 0,
		description: "Quick melee weapon. Can be thrown.",
		dmg: 22,
		hs_multi: 1,
		pierce: 0,
		frames: 0,
		hs_dmg: 22,
		type: "melee",
		group: "Melee",
		firemode: 1,
		trunk_group: 2,
		cost: 20,
		can_buy: 1,
		auto_unlock: 0,
		m_hit_max: 1,
		alert_range: 0,
		imp_amount: -1,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: 155,
		t_damage_gib: 30,
		t_force: 1,
		t_can_lodge_body: 1,
		t_can_lodge_wall: 0,
		t_impact_sound: 73,
	},
	{
		name: "Burst Pistol",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 8,
		spread: 2,
		bullets: 12,
		canshoot: 1,
		hud_str: "sPistolHUD",
		pickup_str: "sBPistolPickup",
		id: "BPistol",
		is2hand: 0,
		recoil: 6,
		description: "Fires 3 shots in quick succession. Holds 12 rounds, with 4 bursts.",
		dmg: 50,
		hs_multi: 2,
		pierce: 0,
		frames: 4,
		hs_dmg: 100,
		type: "pistol",
		group: "Pistol",
		firemode: 3,
		trunk_group: 1,
		cost: 30,
		can_buy: 1,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 180,
		imp_amount: 0.8,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Bolt-Action Rifle",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 9,
		spread: 0.3,
		bullets: 7,
		canshoot: 1,
		hud_str: "sRifleHUD",
		pickup_str: "sRiflePickup",
		id: "Rifle",
		is2hand: 1,
		recoil: 10,
		description: "Very high damage on headshots. Holds 7 rounds.",
		dmg: 200,
		hs_multi: 5,
		pierce: 0.3,
		frames: 60,
		hs_dmg: 1000,
		type: "rifle",
		group: "Rifle",
		firemode: 3,
		trunk_group: 1,
		cost: 0,
		can_buy: 0,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 230,
		imp_amount: 2.1,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "9mm Smg",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 10,
		spread: 3,
		bullets: 30,
		canshoot: 1,
		hud_str: "sTommyHUD",
		pickup_str: "sUziPickup",
		id: "Uzi",
		is2hand: 0,
		recoil: 3,
		description: "Fast but inaccurate. Holds 30 bullets.",
		dmg: 30,
		hs_multi: 1.5,
		pierce: 0,
		frames: 4,
		hs_dmg: 45,
		type: "smg",
		group: "SMG",
		firemode: 4,
		trunk_group: 1,
		cost: 30,
		can_buy: 1,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 250,
		imp_amount: 0.3,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Flashbang",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 11,
		spread: 0,
		bullets: 1,
		canshoot: 0,
		hud_str: "sGrenadeHUD",
		pickup_str: "sGrenadePickup",
		id: "Grenade",
		is2hand: 0,
		recoil: 0,
		description: "Stuns all nearby enemies.",
		dmg: 0,
		hs_multi: 1,
		pierce: 0,
		frames: 0,
		hs_dmg: 0,
		type: "special_use",
		group: "Consumable",
		firemode: 1,
		trunk_group: 2,
		cost: 80,
		can_buy: 1,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 0,
		imp_amount: -1,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "10mm Pistol",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 12,
		spread: 2,
		bullets: 10,
		canshoot: 1,
		hud_str: "sPistolBigHUD",
		pickup_str: "sPistolBigPickup",
		id: "PistolBig",
		is2hand: 1,
		recoil: 8,
		description: "Large high caliber pistol, mid-range. Holds 10 bullets.",
		dmg: 60,
		hs_multi: 2.5,
		pierce: 0.2,
		frames: 4,
		hs_dmg: 150,
		type: "pistol",
		group: "Pistol",
		firemode: 3,
		trunk_group: 1,
		cost: 0,
		can_buy: 0,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 230,
		imp_amount: 0.8,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Combat Shotgun",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 13,
		spread: 15,
		bullets: 5,
		canshoot: 1,
		hud_str: "sPShotgunHUD",
		pickup_str: "sPShotgunPickup",
		id: "PShotgun",
		is2hand: 1,
		recoil: 15,
		description: "Pump-action. Holds 5 shells.",
		dmg: 340,
		hs_multi: 1,
		pierce: 0,
		frames: 30,
		hs_dmg: 340,
		type: "shotgun",
		group: "Shotgun",
		firemode: 3,
		trunk_group: 1,
		cost: 0,
		can_buy: 0,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 230,
		imp_amount: 0.75,
		sg_bullet_count: 6,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Death + Taxes",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 14,
		spread: 4,
		bullets: 14,
		canshoot: 1,
		hud_str: "sRevolverHUD",
		pickup_str: "sRevolver1Pickup",
		id: "Revolver1",
		is2hand: 2,
		recoil: 2,
		description: "High fire rate. Holds 14 bullets.",
		dmg: 50,
		hs_multi: 3,
		pierce: 0,
		frames: 5,
		hs_dmg: 150,
		type: "revolver",
		group: "Revolver",
		firemode: 3,
		trunk_group: 1,
		cost: 80,
		can_buy: 1,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 180,
		imp_amount: 0.8,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Revenant",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 15,
		spread: 3,
		bullets: 5,
		canshoot: 1,
		hud_str: "sRevolver2HUD",
		pickup_str: "sRevolver2Pickup",
		id: "Revolver2",
		is2hand: 1,
		recoil: 7,
		description: "High damage but loud. Holds 5 bullets.",
		dmg: 200,
		hs_multi: 2,
		pierce: 0.4,
		frames: 12,
		hs_dmg: 400,
		type: "revolver",
		group: "Revolver",
		firemode: 3,
		trunk_group: 1,
		cost: 80,
		can_buy: 1,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 310,
		imp_amount: 1.6,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Headhunter",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 16,
		spread: 0.2,
		bullets: 5,
		canshoot: 1,
		hud_str: "sRevolver3HUD",
		pickup_str: "sRevolver3Pickup",
		id: "Revolver3",
		is2hand: 0,
		recoil: 2,
		description: "Extremely powerful headshots. Holds 5 bullets.",
		dmg: 35,
		hs_multi: 15,
		pierce: 0,
		frames: 10,
		hs_dmg: 525,
		type: "revolver",
		group: "Revolver",
		firemode: 3,
		trunk_group: 1,
		cost: 80,
		can_buy: 1,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 180,
		imp_amount: 1.1,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Tactical Smg",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 19,
		spread: 3,
		bullets: 30,
		canshoot: 1,
		hud_str: "sSPistolHUD",
		pickup_str: "sSubPickup",
		id: "Sub",
		is2hand: 1,
		recoil: 1,
		description: "Silent weapon with laser. Holds 30 rounds.",
		dmg: 20,
		hs_multi: 2,
		pierce: 0,
		frames: 5,
		hs_dmg: 40,
		type: "smg",
		group: "SMG",
		firemode: 4,
		trunk_group: 1,
		cost: 80,
		can_buy: 1,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 0,
		imp_amount: 0.8,
		sg_bullet_count: -1,
		laser_sight: 1,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Sledgehammer",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 20,
		spread: 0,
		bullets: 0,
		canshoot: 0,
		hud_str: "sSledgeHUD",
		pickup_str: "sSledgePickup",
		id: "Sledge",
		is2hand: 1,
		recoil: 0,
		description: "Flattens groups of undead.",
		dmg: 500,
		hs_multi: 1,
		pierce: 0,
		frames: 0,
		hs_dmg: 500,
		type: "melee",
		group: "Melee",
		firemode: 0,
		trunk_group: 2,
		cost: 0,
		can_buy: 0,
		auto_unlock: 0,
		m_hit_max: 3,
		alert_range: 0,
		imp_amount: -1,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: ".25 Mousegun",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 21,
		spread: 1,
		bullets: 2,
		canshoot: 1,
		hud_str: "sTommyHUD",
		pickup_str: "sPPistolPickup",
		id: "PPistol",
		is2hand: 0,
		recoil: 5,
		description: "Low power. Holds only 2 bullets. Very quiet.",
		dmg: 25,
		hs_multi: 6,
		pierce: 0,
		frames: 6,
		hs_dmg: 150,
		type: "pistol",
		group: "Pistol",
		firemode: 3,
		trunk_group: 2,
		cost: 40,
		can_buy: 1,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 100,
		imp_amount: 0.4,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Blunderbuss",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 22,
		spread: 20,
		bullets: 1,
		canshoot: 1,
		hud_str: "sBussHUD",
		pickup_str: "sBussPickup",
		id: "Buss",
		is2hand: 0,
		recoil: 35,
		description: "Flintlock shotgun. Holds 1 shot.",
		dmg: 400,
		hs_multi: 1,
		pierce: 0,
		frames: 10,
		hs_dmg: 400,
		type: "shotgun",
		group: "Shotgun",
		firemode: 3,
		trunk_group: 2,
		cost: 80,
		can_buy: 1,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 230,
		imp_amount: 0.75,
		sg_bullet_count: 6,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Throwing Star",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 23,
		spread: 0,
		bullets: 4,
		canshoot: 0,
		hud_str: "sShuriHUD",
		pickup_str: "sShuriPickup",
		id: "Shuri",
		is2hand: 0,
		recoil: 0,
		description: "Can be thrown, stick to surfaces. Set of 4.",
		dmg: 150,
		hs_multi: 1,
		pierce: 0,
		frames: 0,
		hs_dmg: 150,
		type: "special_fire",
		group: "Consumable",
		firemode: 2,
		trunk_group: 2,
		cost: 0,
		can_buy: 0,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 0,
		imp_amount: -1,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: 150,
		t_damage_gib: 30,
		t_force: 0,
		t_can_lodge_body: 1,
		t_can_lodge_wall: 1,
		t_impact_sound: 73,
	},
	{
		name: "Old World Rifle",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 24,
		spread: 1,
		bullets: 20,
		canshoot: 1,
		hud_str: "sAKHUD",
		pickup_str: "sAKPickup",
		id: "AK",
		is2hand: 1,
		recoil: 5,
		description: "High damage. Holds 30 rounds.",
		dmg: 40,
		hs_multi: 1.5,
		pierce: 0.5,
		frames: 7,
		hs_dmg: 60,
		type: "rifle",
		group: "Rifle",
		firemode: 4,
		trunk_group: 1,
		cost: 0,
		can_buy: 0,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 280,
		imp_amount: 0.8,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Suppressed PDW",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 25,
		spread: 4,
		bullets: 50,
		canshoot: 1,
		hud_str: "sP90HUD",
		pickup_str: "sP90Pickup",
		id: "P90",
		is2hand: 0,
		recoil: 1,
		description: "Silent weapon. Low damage, high capacity. 50 rounds.",
		dmg: 15,
		hs_multi: 2,
		pierce: 0,
		frames: 2,
		hs_dmg: 30,
		type: "smg",
		group: "SMG",
		firemode: 4,
		trunk_group: 1,
		cost: 0,
		can_buy: 0,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 0,
		imp_amount: 0.2,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Burst Rifle",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 26,
		spread: 2,
		bullets: 6,
		canshoot: 1,
		hud_str: "sM16HUD",
		pickup_str: "sM16Pickup",
		id: "M16",
		is2hand: 1,
		recoil: 1,
		description: "High damage. Holds 30 rounds.",
		dmg: 40,
		hs_multi: 2,
		pierce: 0,
		frames: 4,
		hs_dmg: 80,
		type: "rifle",
		group: "Rifle",
		firemode: 3,
		trunk_group: 1,
		cost: 0,
		can_buy: 0,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 250,
		imp_amount: 0.8,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Landmine",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 27,
		spread: 0,
		bullets: 1,
		canshoot: 0,
		hud_str: "sMineHUD",
		pickup_str: "sMinePickup",
		id: "Mine",
		is2hand: 1,
		recoil: 0,
		description: "Enormous damage. Detonates on touch. Single use.",
		dmg: 600,
		hs_multi: 1,
		pierce: 0,
		frames: 0,
		hs_dmg: 600,
		type: "special_mine",
		group: "Consumable",
		firemode: 0,
		trunk_group: 2,
		cost: 80,
		can_buy: 1,
		auto_unlock: 0,
		m_hit_max: 100,
		alert_range: 0,
		imp_amount: -1,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Grenade Launcher",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 28,
		spread: 2,
		bullets: 1,
		canshoot: 1,
		hud_str: "sGlHUD",
		pickup_str: "sGlPickup",
		id: "Gl",
		is2hand: 0,
		recoil: 6,
		description: "Massive damage. Large blast radius. 1 shot.",
		dmg: 800,
		hs_multi: 2,
		pierce: 0,
		frames: 10,
		hs_dmg: 1600,
		type: "special_launcher",
		group: "Launcher",
		firemode: 3,
		trunk_group: 1,
		cost: 0,
		can_buy: 0,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 0,
		imp_amount: -1,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: 64,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Auto Grenade Launcher",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 33,
		spread: 2,
		bullets: 6,
		canshoot: 1,
		hud_str: "sGlHUD",
		pickup_str: "sAGlPickup",
		id: "AGl",
		is2hand: 1,
		recoil: 6,
		description: "Massive damage. Large blast radius. 6 rapid shots.",
		dmg: 800,
		hs_multi: 2,
		pierce: 0,
		frames: 10,
		hs_dmg: 1600,
		type: "special_launcher",
		group: "Launcher",
		firemode: 4,
		trunk_group: 1,
		cost: 0,
		can_buy: 0,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 0,
		imp_amount: -1,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: 64,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Severed Lich Arm",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 29,
		spread: 4,
		bullets: 4,
		canshoot: 1,
		hud_str: "sPlasmaHUD",
		pickup_str: "sPlasmaPickup",
		id: "Plasma",
		is2hand: 0,
		recoil: 1,
		description: "Cast a petrifying soul orb. 4 charges.",
		dmg: 15,
		hs_multi: 2,
		pierce: 0,
		frames: 30,
		hs_dmg: 30,
		type: "special_cast",
		group: "Launcher",
		firemode: 3,
		trunk_group: 1,
		cost: 0,
		can_buy: 0,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 0,
		imp_amount: -1,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Semi-Auto Shotgun",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 30,
		spread: 12,
		bullets: 4,
		canshoot: 1,
		hud_str: "sAShotgunHUD",
		pickup_str: "sAShotgunPickup",
		id: "AShotgun",
		is2hand: 1,
		recoil: 15,
		description: "Loud and powerful. Holds 4 shells.",
		dmg: 380,
		hs_multi: 1,
		pierce: 0.5,
		frames: 21,
		hs_dmg: 380,
		type: "shotgun",
		group: "Shotgun",
		firemode: 3,
		trunk_group: 1,
		cost: 120,
		can_buy: 1,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 430,
		imp_amount: 0.75,
		sg_bullet_count: 6,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Suppressed Shotgun",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 31,
		spread: 8,
		bullets: 5,
		canshoot: 1,
		hud_str: "sSShotgunHUD",
		pickup_str: "sSShotgunPickup",
		id: "SShotgun",
		is2hand: 1,
		recoil: 12,
		description: "Silent shotgun. Holds 5 shells.",
		dmg: 230,
		hs_multi: 1,
		pierce: 0,
		frames: 12,
		hs_dmg: 230,
		type: "shotgun",
		group: "Shotgun",
		firemode: 3,
		trunk_group: 1,
		cost: 120,
		can_buy: 1,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 0,
		imp_amount: 0.45,
		sg_bullet_count: 4,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Scythe",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 32,
		spread: 0,
		bullets: 0,
		canshoot: 0,
		hud_str: "sScytheHUD",
		pickup_str: "sScythePickup",
		id: "Scythe",
		is2hand: 1,
		recoil: 0,
		description: "Long-range slashing weapon. Can't be thrown.",
		dmg: 150,
		hs_multi: 1,
		pierce: 0,
		frames: 0,
		hs_dmg: 150,
		type: "melee",
		group: "Melee",
		firemode: 0,
		trunk_group: 1,
		cost: 200,
		can_buy: 1,
		auto_unlock: 0,
		m_hit_max: 1,
		alert_range: 0,
		imp_amount: -1,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Sickle",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 17,
		spread: 0,
		bullets: 1,
		canshoot: 0,
		hud_str: "sSickleHUD",
		pickup_str: "sSicklePickup",
		id: "Sickle",
		is2hand: 0,
		recoil: -1,
		description: "Very high thrown damage. Curved blade prevents lodging.",
		dmg: 0,
		hs_multi: 1,
		pierce: 0,
		frames: 0,
		hs_dmg: 0,
		type: "melee",
		group: "Melee",
		firemode: 0,
		trunk_group: 1,
		cost: 0,
		can_buy: 0,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 0,
		imp_amount: -1,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Crossbow",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 18,
		spread: 0,
		bullets: 5,
		canshoot: 1,
		hud_str: "sXbowHUD",
		pickup_str: "sXbowPickup",
		id: "Xbow",
		is2hand: 1,
		recoil: 2,
		description: "Silent and affected by gravity. 5 bolts.",
		dmg: 300,
		hs_multi: 1,
		pierce: 0,
		frames: 0,
		hs_dmg: 300,
		type: "special_fire",
		group: "Launcher",
		firemode: 3,
		trunk_group: 1,
		cost: 0,
		can_buy: 0,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 0,
		imp_amount: -1,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: 300,
		t_damage_gib: 3,
		t_force: 0.1,
		t_can_lodge_body: 1,
		t_can_lodge_wall: 1,
		t_impact_sound: 73,
	},
	{
		name: "Compound Crossbow",
		is_console: true,
		is_vanilla: false,
		is_working: true,
		index: 34,
		spread: 0,
		bullets: 5,
		canshoot: 1,
		hud_str: "sXbowHUD",
		pickup_str: "sXbowPickup",
		id: "Cbow",
		is2hand: 1,
		recoil: 2,
		description: "1-shot kill. Can retrieve bolts. Holds 2.",
		dmg: 300,
		hs_multi: 1,
		pierce: 0,
		frames: 0,
		hs_dmg: 300,
		type: "special_fire",
		group: "Launcher",
		firemode: 3,
		trunk_group: 1,
		cost: 120,
		can_buy: 1,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 0,
		imp_amount: -1,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: 130,
		t_damage_gib: 3,
		t_force: 0.1,
		t_can_lodge_body: 1,
		t_can_lodge_wall: 1,
		t_impact_sound: 73,
	},
	{
		name: "Tac Knife + .38 Pistol",
		is_console: true,
		is_vanilla: false,
		is_working: true,
		index: 35,
		spread: 2,
		bullets: 8,
		canshoot: 1,
		hud_str: "sPistolHUD",
		pickup_str: "sTKPistolPickup",
		id: "TKPistol",
		is2hand: 0,
		recoil: 6,
		description: "Dual wield knife and pistol. Holds 8 bullets.",
		dmg: 50,
		hs_multi: 2,
		pierce: 0,
		frames: 4,
		hs_dmg: 100,
		type: "pistol",
		group: "Pistol",
		firemode: 3,
		trunk_group: 1,
		cost: 80,
		can_buy: 1,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 180,
		imp_amount: 0.8,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Last Breath",
		is_console: true,
		is_vanilla: false,
		is_working: false,
		index: 36,
		spread: 0,
		bullets: 1,
		canshoot: 0,
		hud_str: "sPlasmaHUD",
		pickup_str: "sLastBPickup",
		id: "LastB",
		is2hand: 1,
		recoil: -1,
		description: "Slows down time when aiming.",
		dmg: 150,
		hs_multi: 1,
		pierce: 0,
		frames: 0,
		hs_dmg: 150,
		type: "special_use",
		group: "Special",
		firemode: 0,
		trunk_group: 2,
		cost: 200,
		can_buy: 1,
		auto_unlock: 0,
		m_hit_max: -1,
		alert_range: 0,
		imp_amount: -1,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Melee",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: 4,
		spread: 0,
		bullets: 0,
		canshoot: 0,
		hud_str: null,
		pickup_str: "sPunchPickupDark",
		id: "Punch",
		is2hand: 0,
		recoil: 0,
		description: "Regular punch for Reaper, or melee smash.",
		dmg: 12,
		hs_multi: 1,
		pierce: 0,
		frames: 0,
		hs_dmg: 12,
		type: "none",
		group: 'Revolver',
		firemode: 0,
		trunk_group: 2,
		cost: 0,
		can_buy: 0,
		auto_unlock: 0,
		m_hit_max: 1,
		alert_range: 0,
		imp_amount: -1,
		sg_bullet_count: -1,
		laser_sight: 0,
		explosion_radius: -1,
		t_damage: -1,
		t_damage_gib: -1,
		t_force: -1,
		t_can_lodge_body: -1,
		t_can_lodge_wall: -1,
		t_impact_sound: -1,
	},
	{
		name: "Player Choice",
		is_console: false,
		is_vanilla: true,
		is_working: true,
		index: -1,
		spread: 0,
		bullets: 0,
		canshoot: null,
		hud_str: null,
		pickup_str: "sCustomPickupLargeDark",
		id: "PChoice",
		is2hand: null,
		recoil: 0,
		description: "Player's last choice in the campaign. The default in vanilla.",
		dmg: 0,
		hs_multi: 0,
		pierce: 0,
		frames: 0,
		hs_dmg: 0,
		type: "none",
		group: 'Revolver',
		firemode: null,
		trunk_group: null,
		cost: null,
		can_buy: null,
		auto_unlock: null,
		m_hit_max: null,
		alert_range: 0,
		imp_amount: null,
		sg_bullet_count: null,
		laser_sight: null,
		explosion_radius: null,
		t_damage: null,
		t_damage_gib: null,
		t_force: null,
		t_can_lodge_body: null,
		t_can_lodge_wall: null,
		t_impact_sound: null,
	},
];