
export default [
	{
		name: 'Reaper',
		sprite: 'sP',
		id: 'P',
		hp: 30,
		run_speed: 2,
		wpn1_index: 0,
		wpn2_index: 4,
		blood_type: 5,
		group: 'Reaper',
		isBoss: false,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: true,
			melee_smash: true,
			melee_throw: true,
			weapon_pickups: true,
			vent: true,
			cover: true,
			ladder: true,
			cartrunk: true,
			charon: true,
		}
	},
	{
		name: 'Punk',
		sprite: 'sZomb1',
		id: 'Zomb1',
		hp: 100,
		run_speed: 2,
		wpn1_index: 1,
		wpn2_index: 4,
		blood_type: 1,
		group: 'Zombie',
		isBoss: false,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: true,
			melee_smash: false,
			melee_throw: true,
			weapon_pickups: true,
			vent: false,
			cover: true,
			ladder: false,
			cartrunk: true,
			charon: true,
		}
	},
	{
		name: 'Bully',
		sprite: 'sZomb4',
		id: 'Zomb4',
		hp: 100,
		run_speed: 2,
		wpn1_index: 4,
		wpn2_index: 4,
		blood_type: 1,
		group: 'Zombie',
		isBoss: false,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: false,
			melee_smash: true,
			melee_throw: false,
			weapon_pickups: false,
			vent: false,
			cover: false,
			ladder: false,
			cartrunk: false,
			charon: false,
		}
	},
	{
		name: 'Bouncer',
		sprite: 'sZomb2',
		id: 'Zomb2',
		hp: 380,
		run_speed: 1.2,
		wpn1_index: 2,
		wpn2_index: 4,
		blood_type: 1,
		group: 'Zombie',
		isBoss: false,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: true,
			melee_smash: false,
			melee_throw: true,
			weapon_pickups: true,
			vent: false,
			cover: false,
			ladder: false,
			cartrunk: true,
			charon: true,
		}
	},
	{
		name: 'Shambler',
		sprite: 'sZomb3',
		id: 'Zomb3',
		hp: 170,
		run_speed: 2,
		wpn1_index: 10,
		wpn2_index: 4,
		blood_type: 1,
		group: 'Zombie',
		isBoss: false,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: true,
			melee_smash: false,
			melee_throw: true,
			weapon_pickups: true,
			vent: false,
			cover: true,
			ladder: false,
			cartrunk: true,
			charon: true,
		}
	},
	{
		name: 'Shrieker',
		sprite: 'sZombCameraHead',
		id: 'ZombCameraHead',
		hp: 25,
		run_speed: 0,
		wpn1_index: 4,
		wpn2_index: 4,
		blood_type: 1,
		group: 'Zombie',
		isBoss: false,
		isPlayable: false,
		canRagdoll: false,
		abilities: {
			gun_weapons: false,
			melee_smash: false,
			melee_throw: false,
			weapon_pickups: false,
			vent: false,
			cover: false,
			ladder: false,
			cartrunk: false,
			charon: false,
		}
	},
	{
		name: 'Puff',
		sprite: 'sZombBoss1',
		id: 'ZombBoss1',
		hp: 280,
		run_speed: 1.2,
		wpn1_index: 12,
		wpn2_index: 4,
		blood_type: 1,
		group: 'Zombie',
		isBoss: true,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: true,
			melee_smash: false,
			melee_throw: true,
			weapon_pickups: true,
			vent: false,
			cover: true,
			ladder: false,
			cartrunk: true,
			charon: true,
		}
	},
	{
		name: 'Roland',
		sprite: 'sZombBoss2',
		id: 'ZombBoss2',
		hp: 500,
		run_speed: 2,
		wpn1_index: 10,
		wpn2_index: 4,
		blood_type: 1,
		group: 'Zombie',
		isBoss: true,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: true,
			melee_smash: false,
			melee_throw: true,
			weapon_pickups: true,
			vent: false,
			cover: true,
			ladder: false,
			cartrunk: true,
			charon: true,
		}
	},
	{
		name: 'Incubus',
		sprite: 'sVamp1',
		id: 'Vamp1',
		hp: 150,
		run_speed: 2,
		wpn1_index: 12,
		wpn2_index: 4,
		blood_type: 1,
		group: 'Vampire',
		isBoss: false,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: true,
			melee_smash: false,
			melee_throw: true,
			weapon_pickups: true,
			vent: false,
			cover: true,
			ladder: false,
			cartrunk: true,
			charon: true,
		}
	},
	{
		name: 'Succubus',
		sprite: 'sVamp2',
		id: 'Vamp2',
		hp: 150,
		run_speed: 3,
		wpn1_index: 4,
		wpn2_index: 4,
		blood_type: 1,
		group: 'Vampire',
		isBoss: false,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: false,
			melee_smash: true,
			melee_throw: false,
			weapon_pickups: false,
			vent: false,
			cover: true,
			ladder: false,
			cartrunk: false,
			charon: false,
		}
	},
	{
		name: 'Bartender',
		sprite: 'sVamp3',
		id: 'Vamp3',
		hp: 200,
		run_speed: 2,
		wpn1_index: 13,
		wpn2_index: 4,
		blood_type: 1,
		group: 'Vampire',
		isBoss: false,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: true,
			melee_smash: false,
			melee_throw: true,
			weapon_pickups: true,
			vent: false,
			cover: false,
			ladder: false,
			cartrunk: true,
			charon: true,
		}
	},
	{
		name: 'Nightcrawler',
		sprite: 'sVamp4',
		id: 'Vamp4',
		hp: 340,
		run_speed: 1.2,
		wpn1_index: 25,
		wpn2_index: 4,
		blood_type: 1,
		group: 'Vampire',
		isBoss: false,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: true,
			melee_smash: false,
			melee_throw: true,
			weapon_pickups: true,
			vent: false,
			cover: false,
			ladder: false,
			cartrunk: true,
			charon: true,
		}
	},
	{
		name: 'Evelyn',
		sprite: 'sVampBoss11',
		id: 'VampBoss11',
		hp: 220,
		run_speed: 2,
		wpn1_index: 14,
		wpn2_index: 4,
		blood_type: 1,
		group: 'Vampire',
		isBoss: true,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: true,
			melee_smash: false,
			melee_throw: true,
			weapon_pickups: true,
			vent: false,
			cover: true,
			ladder: false,
			cartrunk: true,
			charon: true,
		}
	},
	{
		name: 'Amber',
		sprite: 'sVampBoss12',
		id: 'VampBoss12',
		hp: 230,
		run_speed: 4,
		wpn1_index: 4,
		wpn2_index: 4,
		blood_type: 1,
		group: 'Vampire',
		isBoss: true,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: false,
			melee_smash: true,
			melee_throw: false,
			weapon_pickups: false,
			vent: false,
			cover: false,
			ladder: false,
			cartrunk: false,
			charon: false,
		}
	},
	{
		name: 'Madam Stela',
		sprite: 'sVampBoss2',
		id: 'VampBoss2',
		hp: 250,
		run_speed: 2,
		wpn1_index: 13,
		wpn2_index: 4,
		blood_type: 1,
		group: 'Vampire',
		isBoss: true,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: true,
			melee_smash: false,
			melee_throw: true,
			weapon_pickups: true,
			vent: false,
			cover: false,
			ladder: false,
			cartrunk: true,
			charon: true,
		}
	},
	{
		name: 'Cardinal',
		sprite: 'sOni1',
		id: 'Oni1',
		hp: 150,
		run_speed: 2.5,
		wpn1_index: 20,
		wpn2_index: 4,
		blood_type: 4,
		group: 'Demon',
		isBoss: false,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: false,
			melee_smash: true,
			melee_throw: false,
			weapon_pickups: false,
			vent: false,
			cover: false,
			ladder: false,
			cartrunk: false,
			charon: false,
		}
	},
	{
		name: 'Maiden',
		sprite: 'sOni2',
		id: 'Oni2',
		hp: 150,
		run_speed: 2,
		wpn1_index: 26,
		wpn2_index: 4,
		blood_type: 4,
		group: 'Demon',
		isBoss: false,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: true,
			melee_smash: false,
			melee_throw: true,
			weapon_pickups: true,
			vent: false,
			cover: true,
			ladder: false,
			cartrunk: true,
			charon: true,
		}
	},
	{
		name: 'Patriarch',
		sprite: 'sOni3',
		id: 'Oni3',
		hp: 400,
		run_speed: 1.2,
		wpn1_index: 2,
		wpn2_index: 4,
		blood_type: 4,
		group: 'Demon',
		isBoss: false,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: true,
			melee_smash: false,
			melee_throw: true,
			weapon_pickups: true,
			vent: false,
			cover: false,
			ladder: false,
			cartrunk: true,
			charon: true,
		}
	},
	{
		name: 'Sergeant',
		sprite: 'sSkele1',
		id: 'Skele1',
		hp: 350,
		run_speed: 1,
		wpn1_index: 20,
		wpn2_index: 4,
		blood_type: 3,
		group: 'Skeleton',
		isBoss: false,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: false,
			melee_smash: true,
			melee_throw: false,
			weapon_pickups: false,
			vent: false,
			cover: false,
			ladder: false,
			cartrunk: false,
			charon: false,
		}
	},
	{
		name: 'Grunt',
		sprite: 'sSkele2',
		id: 'Skele2',
		hp: 130,
		run_speed: 2,
		wpn1_index: 24,
		wpn2_index: 4,
		blood_type: 3,
		group: 'Skeleton',
		isBoss: false,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: true,
			melee_smash: false,
			melee_throw: true,
			weapon_pickups: true,
			vent: false,
			cover: true,
			ladder: false,
			cartrunk: true,
			charon: true,
		}
	},
	{
		name: 'Technician',
		sprite: 'sSkele3',
		id: 'Skele3',
		hp: 160,
		run_speed: 1.75,
		wpn1_index: 28,
		wpn2_index: 27,
		blood_type: 3,
		group: 'Skeleton',
		isBoss: false,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: true,
			melee_smash: false,
			melee_throw: true,
			weapon_pickups: true,
			vent: false,
			cover: true,
			ladder: false,
			cartrunk: true,
			charon: true,
		}
	},
	{
		name: 'General',
		sprite: 'sSkele5',
		id: 'Skele5',
		hp: 200,
		run_speed: 2,
		wpn1_index: 29,
		wpn2_index: 4,
		blood_type: 3,
		group: 'Skeleton',
		isBoss: false,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: true,
			melee_smash: false,
			melee_throw: true,
			weapon_pickups: true,
			vent: false,
			cover: false,
			ladder: false,
			cartrunk: true,
			charon: true,
		}
	},
	{
		name: 'Ibzan',
		sprite: 'sSkeleBoss2',
		id: 'SkeleBoss2',
		hp: 200,
		run_speed: 2.5,
		wpn1_index: 15,
		wpn2_index: 4,
		blood_type: 5,
		group: 'Skeleton',
		isBoss: true,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: true,
			melee_smash: false,
			melee_throw: true,
			weapon_pickups: true,
			vent: false,
			cover: true,
			ladder: false,
			cartrunk: true,
			charon: true,
		}
	},
	{
		name: 'Timur the Tinkerer',
		sprite: 'sSkeleBoss1',
		id: 'SkeleBoss1',
		hp: 100,
		run_speed: 2,
		wpn1_index: 27,
		wpn2_index: 4,
		blood_type: 3,
		group: 'Skeleton',
		isBoss: true,
		isPlayable: false,
		canRagdoll: false,
		abilities: {
			gun_weapons: true,
			melee_smash: false,
			melee_throw: true,
			weapon_pickups: true,
			vent: false,
			cover: false,
			ladder: false,
			cartrunk: true,
			charon: true,
		}
	},
	{
		name: 'Vall',
		sprite: 'sOniBoss1',
		id: 'OniBoss1',
		hp: 1,
		run_speed: 0,
		wpn1_index: 9,
		wpn2_index: 4,
		blood_type: 4,
		group: 'Demon',
		isBoss: true,
		isPlayable: false,
		canRagdoll: false,
		abilities: {
			gun_weapons: false,
			melee_smash: false,
			melee_throw: false,
			weapon_pickups: false,
			vent: false,
			cover: false,
			ladder: false,
			cartrunk: false,
			charon: false,
		}
	},
	{
		name: 'Charon',
		sprite: 'sCharon',
		id: 'Charon',
		hp: 600,
		run_speed: 2.5,
		wpn1_index: 29,
		wpn2_index: 29,
		blood_type: 4,
		group: 'Misc',
		isBoss: false,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: true,
			melee_smash: false,
			melee_throw: true,
			weapon_pickups: true,
			vent: false,
			cover: false,
			ladder: false,
			cartrunk: true,
			charon: true,
		}
	},
	{
		name: 'Cerberus',
		sprite: 'sDog1',
		id: 'Dog1',
		hp: 155,
		run_speed: 2.5,
		wpn1_index: 4,
		wpn2_index: 4,
		blood_type: 1,
		group: 'Misc',
		isBoss: false,
		isPlayable: true,
		canRagdoll: true,
		abilities: {
			gun_weapons: false,
			melee_smash: true,
			melee_throw: false,
			weapon_pickups: false,
			vent: false,
			cover: false,
			ladder: false,
			cartrunk: false,
			charon: false,
		}
	},
	{
		name: 'Cat',
		sprite: 'sCat',
		id: 'Cat',
		hp: 15,
		run_speed: 1.5,
		wpn1_index: 4,
		wpn2_index: 4,
		blood_type: 1,
		group: 'Misc',
		isBoss: false,
		isPlayable: true,
		canRagdoll: false,
		abilities: {
			gun_weapons: false,
			melee_smash: false,
			melee_throw: false,
			weapon_pickups: false,
			vent: false,
			cover: false,
			ladder: false,
			cartrunk: false,
			charon: false,
		}
	},
];
