
export default {
	1: {
		index: 1,
		name: 'Red',
		usedby: 'Zombie, Vampire',
		hide: false,
		baseColor: 'darkred',
		outlineColor: 'red',
	},
	2: {
		index: 2,
		name: 'Red',
		usedby: 'NA',
		hide: true,
		baseColor: 'darkred',
		outlineColor: 'red',
	},
	3: {
		index: 3,
		name: 'Red',
		usedby: 'NA',
		hide: true,
		baseColor: 'darkred',
		outlineColor: 'red',
	},
	4: {
		index: 4,
		name: 'Dark Grey',
		usedby: 'Skeletons',
		hide: false,
		baseColor: 'darkred',
		outlineColor: 'red',
	},
	5: {
		index: 5,
		name: 'Black',
		usedby: 'Repaer, Ibzan',
		hide: false,
		baseColor: 'darkred',
		outlineColor: 'red',
	}
}